@charset "UTF-8";
@font-face {
  font-family: "woutick-icons";
  src: url("fonts/woutick-icons.eot?wrxblw");
  src: url("fonts/woutick-icons.eot?wrxblw#iefix") format("embedded-opentype"), url("fonts/woutick-icons.ttf?wrxblw") format("truetype"), url("fonts/woutick-icons.woff?wrxblw") format("woff"), url("fonts/woutick-icons.svg?wrxblw#woutick-icons") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}
[class^=icon_], [class*=" icon_"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "woutick-icons" !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon_w_icons_clock-copia1:before {
  content: "";
}

.icon_w_icons_euroReload:before {
  content: "";
}

.icon_w_icons_face_sad:before {
  content: "";
}

.icon_w_icons_Question_Circle:before {
  content: "";
}

.icon_w_icons_waiter:before {
  content: "";
}

.icon_w_icons_account:before {
  content: "";
}

.icon_w_icons_add:before {
  content: "";
}

.icon_w_icons_add_circle:before {
  content: "";
}

.icon_w_icons_add_one:before {
  content: "";
}

.icon_w_icons_adjust:before {
  content: "";
}

.icon_w_icons_arrow_drag_bottomL:before {
  content: "";
}

.icon_w_icons_arrow_drag_bottomR:before {
  content: "";
}

.icon_w_icons_arrow_drag_topL:before {
  content: "";
}

.icon_w_icons_arrow_drag_topR:before {
  content: "";
}

.icon_w_icons_arrow_function_down:before {
  content: "";
}

.icon_w_icons_arrow_function_left:before {
  content: "";
}

.icon_w_icons_arrow_function_right:before {
  content: "";
}

.icon_w_icons_arrow_function_up:before {
  content: "";
}

.icon_w_icons_arrow_nav_down:before {
  content: "";
}

.icon_w_icons_arrow_nav_left:before {
  content: "";
}

.icon_w_icons_arrow_nav_right:before {
  content: "";
}

.icon_w_icons_arrow_nav_up:before {
  content: "";
}

.icon_w_icons_ban:before {
  content: "";
}

.icon_w_icons_basket:before {
  content: "";
}

.icon_w_icons_bell:before {
  content: "";
}

.icon_w_icons_bell_cancel:before {
  content: "";
}

.icon_w_icons_bell_outline:before {
  content: "";
}

.icon_w_icons_bell_small:before {
  content: "";
}

.icon_w_icons_bolt:before {
  content: "";
}

.icon_w_icons_bolt_cricle:before {
  content: "";
}

.icon_w_icons_book:before {
  content: "";
}

.icon_w_icons_book_big:before {
  content: "";
}

.icon_w_icons_book_big_outline:before {
  content: "";
}

.icon_w_icons_book_outline:before {
  content: "";
}

.icon_w_icons_box:before {
  content: "";
}

.icon_w_icons_box_office:before {
  content: "";
}

.icon_w_icons_CA:before {
  content: "";
}

.icon_w_icons_calendar:before {
  content: "";
}

.icon_w_icons_calendar_config:before {
  content: "";
}

.icon_w_icons_camera:before {
  content: "";
}

.icon_w_icons_camera_off:before {
  content: "";
}

.icon_w_icons_cancel:before {
  content: "";
}

.icon_w_icons_cancel_circle:before {
  content: "";
}

.icon_w_icons_card_list:before {
  content: "";
}

.icon_w_icons_card_stack:before {
  content: "";
}

.icon_w_icons_chat:before {
  content: "";
}

.icon_w_icons_chat_outline:before {
  content: "";
}

.icon_w_icons_check:before {
  content: "";
}

.icon_w_icons_check_circle:before {
  content: "";
}

.icon_w_icons_circle_outline:before {
  content: "";
}

.icon_w_icons_clip:before {
  content: "";
}

.icon_w_icons_clock-copia:before {
  content: "";
}

.icon_w_icons_clock:before {
  content: "";
}

.icon_w_icons_code:before {
  content: "";
}

.icon_w_icons_copy:before {
  content: "";
}

.icon_w_icons_cowork:before {
  content: "";
}

.icon_w_icons_delete:before {
  content: "";
}

.icon_w_icons_doc:before {
  content: "";
}

.icon_w_icons_doc_add:before {
  content: "";
}

.icon_w_icons_download:before {
  content: "";
}

.icon_w_icons_drag:before {
  content: "";
}

.icon_w_icons_edit:before {
  content: "";
}

.icon_w_icons_edit_box:before {
  content: "";
}

.icon_w_icons_edit_circle:before {
  content: "";
}

.icon_w_icons_envelope:before {
  content: "";
}

.icon_w_icons_euro:before {
  content: "";
}

.icon_w_icons_exclamation_circle:before {
  content: "";
}

.icon_w_icons_exclamation_warning:before {
  content: "";
}

.icon_w_icons_exclamation-12:before {
  content: "";
}

.icon_w_icons_exclamation-148:before {
  content: "";
}

.icon_w_icons_exhibitor:before {
  content: "";
}

.icon_w_icons_exit:before {
  content: "";
}

.icon_w_icons_expand:before {
  content: "";
}

.icon_w_icons_eye_closed:before {
  content: "";
}

.icon_w_icons_eye_open:before {
  content: "";
}

.icon_w_icons_face_happy:before {
  content: "";
}

.icon_w_icons_food:before {
  content: "";
}

.icon_w_icons_gaming:before {
  content: "";
}

.icon_w_icons_handshake:before {
  content: "";
}

.icon_w_icons_heart:before {
  content: "";
}

.icon_w_icons_heart_broken:before {
  content: "";
}

.icon_w_icons_helpCenter:before {
  content: "";
}

.icon_w_icons_history:before {
  content: "";
}

.icon_w_icons_hotel:before {
  content: "";
}

.icon_w_icons_hotel_big:before {
  content: "";
}

.icon_w_icons_inbox:before {
  content: "";
}

.icon_w_icons_inbox_out:before {
  content: "";
}

.icon_w_icons_inbox_small:before {
  content: "";
}

.icon_w_icons_information:before {
  content: "";
}

.icon_w_icons_information_circle:before {
  content: "";
}

.icon_w_icons_insert_one:before {
  content: "";
}

.icon_w_icons_location_pin:before {
  content: "";
}

.icon_w_icons_location_pin_circle:before {
  content: "";
}

.icon_w_icons_location_pin_small:before {
  content: "";
}

.icon_w_icons_locationPinAdd:before {
  content: "";
}

.icon_w_icons_lock:before {
  content: "";
}

.icon_w_icons_lock_open:before {
  content: "";
}

.icon_w_icons_medal:before {
  content: "";
}

.icon_w_icons_menu_hamburger:before {
  content: "";
}

.icon_w_icons_mic:before {
  content: "";
}

.icon_w_icons_news:before {
  content: "";
}

.icon_w_icons_next:before {
  content: "";
}

.icon_w_icons_note:before {
  content: "";
}

.icon_w_icons_open_in_new:before {
  content: "";
}

.icon_w_icons_options:before {
  content: "";
}

.icon_w_icons_paper_plane:before {
  content: "";
}

.icon_w_icons_pause:before {
  content: "";
}

.icon_w_icons_people:before {
  content: "";
}

.icon_w_icons_people_add:before {
  content: "";
}

.icon_w_icons_people_send:before {
  content: "";
}

.icon_w_icons_phone:before {
  content: "";
}

.icon_w_icons_picture:before {
  content: "";
}

.icon_w_icons_picture_add:before {
  content: "";
}

.icon_w_icons_play_button:before {
  content: "";
}

.icon_w_icons_play_button_disabled:before {
  content: "";
}

.icon_w_icons_playButtonAdd:before {
  content: "";
}

.icon_w_icons_playButtonFilled:before {
  content: "";
}

.icon_w_icons_pointer:before {
  content: "";
}

.icon_w_icons_qr_code:before {
  content: "";
}

.icon_w_icons_ranking:before {
  content: "";
}

.icon_w_icons_refresh:before {
  content: "";
}

.icon_w_icons_remove:before {
  content: "";
}

.icon_w_icons_remove_circle:before {
  content: "";
}

.icon_w_icons_retract:before {
  content: "";
}

.icon_w_icons_rewind:before {
  content: "";
}

.icon_w_icons_save:before {
  content: "";
}

.icon_w_icons_scan:before {
  content: "";
}

.icon_w_icons_search_small:before {
  content: "";
}

.icon_w_icons_selection:before {
  content: "";
}

.icon_w_icons_selection_cancel:before {
  content: "";
}

.icon_w_icons_settings_small:before {
  content: "";
}

.icon_w_icons_share:before {
  content: "";
}

.icon_w_icons_slider:before {
  content: "";
}

.icon_w_icons_slider_cancel:before {
  content: "";
}

.icon_w_icons_slider_checked:before {
  content: "";
}

.icon_w_icons_slider_question:before {
  content: "";
}

.icon_w_icons_slider_suspensive:before {
  content: "";
}

.icon_w_icons_slider_warning:before {
  content: "";
}

.icon_w_icons_social_artstation:before {
  content: "";
}

.icon_w_icons_social_deviantart:before {
  content: "";
}

.icon_w_icons_social_discord:before {
  content: "";
}

.icon_w_icons_social_facebook:before {
  content: "";
}

.icon_w_icons_social_instagram:before {
  content: "";
}

.icon_w_icons_social_linkedin:before {
  content: "";
}

.icon_w_icons_social_pinterest:before {
  content: "";
}

.icon_w_icons_social_reddit:before {
  content: "";
}

.icon_w_icons_social_spotify:before {
  content: "";
}

.icon_w_icons_social_twitter:before {
  content: "";
}

.icon_w_icons_social_youtube:before {
  content: "";
}

.icon_w_icons_stack:before {
  content: "";
}

.icon_w_icons_star:before {
  content: "";
}

.icon_w_icons_star_big:before {
  content: "";
}

.icon_w_icons_star_big_outline:before {
  content: "";
}

.icon_w_icons_star_circle:before {
  content: "";
}

.icon_w_icons_star_outline:before {
  content: "";
}

.icon_w_icons_star_podium:before {
  content: "";
}

.icon_w_icons_suitcase:before {
  content: "";
}

.icon_w_icons_suspensive:before {
  content: "";
}

.icon_w_icons_sync:before {
  content: "";
}

.icon_w_icons_tables:before {
  content: "";
}

.icon_w_icons_tables_config:before {
  content: "";
}

.icon_w_icons_tag:before {
  content: "";
}

.icon_w_icons_team:before {
  content: "";
}

.icon_w_icons_ticket:before {
  content: "";
}

.icon_w_icons_ticket_access:before {
  content: "";
}

.icon_w_icons_ticket_screen:before {
  content: "";
}

.icon_w_icons_ticket_send:before {
  content: "";
}

.icon_w_icons_ticket_send_big .path1:before {
  content: "";
  color: #010001;
}

.icon_w_icons_ticket_send_big .path2:before {
  content: "";
  margin-left: -1em;
  color: #010001;
}

.icon_w_icons_ticket_send_big .path3:before {
  content: "";
  margin-left: -1em;
  color: #010001;
}

.icon_w_icons_ticket_send_big .path4:before {
  content: "";
  margin-left: -1em;
  color: #010001;
}

.icon_w_icons_ticket_send_big .path5:before {
  content: "";
  margin-left: -1em;
  color: black;
}

.icon_w_icons_ticket_send_big .path6:before {
  content: "";
  margin-left: -1em;
  color: black;
}

.icon_w_icons_ticketAdd:before {
  content: "";
}

.icon_w_icons_ticketConfig:before {
  content: "";
}

.icon_w_icons_tickets:before {
  content: "";
}

.icon_w_icons_trifold:before {
  content: "";
}

.icon_w_icons_upload:before {
  content: "";
}

.icon_w_icons_world:before {
  content: "";
}

.icon_w_icons_woutick:before {
  content: "";
}