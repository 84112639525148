@import "partials/variables";
@import "partials/mixins";
@import "partials/typography";
@import url("https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700,900");

body {
  font-family: "Poppins", sans-serif;
  background-color: $backgroundColor;
  color: $fontColor;
}

button:focus,
input:focus {
  outline: none;
}

*:not(i) {
  font-family: $textFontFamily !important;
}

ion-header.transparent {
  background: transparent;
  backdrop-filter: blur(0px);

  ion-toolbar {
    --background: transparent;

    ion-title {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: left;
      @include principal6(white);
      padding-bottom: 0;
      padding-top: 0;
      padding-inline-start: 60px !important;
    }

    ion-buttons {
      &.mobile {
        display: none;
      }

      ion-menu-button,
      ion-back-button {
        color: white;
      }

      ion-button {
        .icon {
          color: white;
          font-size: 27px;
        }
      }
    }
  }
}

ion-footer.transparent {
  background: transparent;
  backdrop-filter: blur(0px);

  ion-toolbar {
    --background: transparent;
  }
}

ion-content.transparent {
  --background: rgba(0, 0, 0, 0.2);
}

ion-header.menu {
  background-color: $primaryColor;

  ion-toolbar {
    --background: var(--primaryColor);

    ion-buttons {
      &.mobile {
        display: none;
      }

      ion-menu-button,
      ion-back-button {
        color: $primaryIcon;
      }

      ion-button {
        // &.search-button {
        //   display: none;
        // }

        .icon {
          color: $primaryIcon;
          font-size: 27px;

          &.icon-cancel-circle {
            color: $cancel !important;
          }
        }
      }
    }

    ion-title {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 100%;
      text-align: left;
      @include principal6($primaryTitle);
      padding-bottom: 0;
      padding-top: 0;
      padding-inline-start: 60px !important;
    }
  }

  &.secondary {
    background-color: $secondaryColor;

    ion-toolbar {
      --background: var(--secondaryColor);

      ion-buttons {
        ion-menu-button,
        ion-back-button {
          color: $secondaryIcon;
        }

        ion-button {
          .icon {
            color: $secondaryIcon;
          }
        }
      }

      ion-title {
        @include principal6($secondaryTitle);
      }
    }
  }
}

ion-footer.menu {
  background-color: $primaryColor;

  &.secondary {
    background-color: $secondaryColor;
  }

  ion-toolbar {
    --background: transparent;
  }
}

ion-toolbar {
  custom-button {
    button {
      height: auto !important;

      div {
        padding: 0 10px !important;
        margin: 0 8px !important;

        ion-title {
          position: relative !important;
          padding-inline-start: 0 !important;
        }
      }
    }
  }
}

.ion-page {
  // background-color: $backgroundColor;
  background: url("../img/background.png") no-repeat center center / cover;
}

ion-content {
  --ion-background-color: #eeeeee;
  color: $fontColor;
}

.loading,
.no-content {
  text-align: center;
  position: relative;
  padding: 0;
  display: grid;
  height: 100px;
  @include subtitulo1(rgba($fontColor, 0.5));
}

ion-toolbar.searchbar-container {
  position: relative;
  top: 0;
  width: 100%;
  z-index: 0;
  transition: top 0.5s ease 0s;
  --background: transparent;
  --border-width: 0;

  &.hide {
    height: 0;
    width: 0;
    top: -100px;
    left: 100px;
  }

  ion-searchbar {
    padding: 12px 8px;
    --background: white;
  }
}

ion-list.card {
  background-color: white;
  border-radius: 15px;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  width: 95%;
  margin: auto;
}

button.fab-button {
  position: absolute;
  z-index: 10;
  bottom: 0;
  right: 0;
  margin: 35px;
  padding: 12px;
  border-radius: 25px;
  transition: all linear 0.5s;

  &.search {
    background-color: $interactive !important;
  }

  &.cancel {
    background-color: $cancel !important;

    .mat-button-wrapper {
      padding: 0;

      .icon {
        font-size: 37px;
      }
    }
  }

  &.cart {
    position: fixed;
    background-color: $emphasis !important;
  }

  .icon {
    color: $primaryIcon;
    font-size: 27px;
  }
}

ion-segment.tabs {
  --background: white;
  box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px,
    rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
  margin: auto;
  display: flex;
  justify-content: center;

  ion-segment-button {
    min-height: 40px;
    height: 40px;
    text-transform: capitalize;
    font-style: italic;
    color: $interactive;
    --background-checked: transparent;
    --background-focused: transparent;
    --background-focused-opacity: 0;
    --background-hover: transparent;
    --background-hover-opacity: 0;
    --indicator-color: var(--emphasis);
    --indicator-height: 5px;
    --color-hover: var(--selectedText);
    --color-checked: var(--selectedText);

    &.segment-button-checked {
      font-weight: 900;
      font-style: normal;
      color: $selectedText;
    }
  }
}

.segment-button-indicator-background {
  border-top-right-radius: 25px !important;
  border-top-left-radius: 25px !important;
}

.order-popover {
  --backdrop-opacity: 0.4;
  // --height: 280px;
  --width: 400px;

  &.sc-ion-popover-ios-h {
    .popover-content {
      left: 0 !important;
      width: 100% !important;
    }
  }

  .popover-content {
    height: 95% !important;
    top: auto !important;
    bottom: 0 !important;
    position: absolute !important;
    background: white !important;
    overflow: hidden !important;
    border-top-right-radius: 10px !important;
    border-top-left-radius: 10px !important;
    border-bottom-right-radius: 0 !important;
    border-bottom-left-radius: 0 !important;

    order-pro {
      height: 100% !important;
    }
  }

  .buttons-container {
    display: flex;
    justify-content: space-around;
    bottom: 0;
    padding: 8px 0;
    width: 100%;
    background-color: white;
  }
}

.default-popover {
  --backdrop-opacity: 0.4;
  // --height: 280px;
  --width: 400px;

  &.sc-ion-popover-ios-h {
    --width: 360px !important;
  }

  .popover-content {
    top: 20% !important;
    position: static !important;
    background: white !important;
    overflow: hidden !important;
    border-radius: 20px !important;
  }

  ion-header {
    height: 40px;
    background: #1d1d1b;
    display: flex;

    ion-title {
      @include principal5(white);
      font-weight: 900;
      margin: auto;
      text-align: center;
    }
  }

  .container {
    display: grid;
    grid-template-columns: 100%;
    margin: auto;
    height: 100%;

    .info-container {
      margin: 16px;

      .products {
        width: 100%;
        display: grid;
        grid-template-columns: auto auto;
        justify-content: space-between;

        &.dots {
          background: url("../img/dot.png") repeat-x 0% 80%;
        }
      }

      ion-item {
        --inner-padding-end: 0;
        --padding-start: 0;
      }

      ion-title {
        position: relative;
        color: black;
        font-size: 18px;
        font-weight: bold;
        padding: 0;
        background-color: white;

        &.left {
          text-align: left;
        }

        &.center {
          text-align: center;
        }

        &.right {
          text-align: right;
        }

        &.bolder {
          font-weight: 900;
        }

        &.lighter {
          font-weight: 400;
        }

        &.upper {
          text-transform: uppercase;
        }

        &.pad-left {
          padding-left: 5px;
        }

        &.pad-right {
          padding-right: 5px;
        }
      }
    }

    .buttons-container {
      display: flex;
      justify-content: space-around;
      // position: fixed;
      bottom: 0;
      padding: 8px 0;
      width: 100%;
      // border-top: 1px solid black;
      background-color: white;
    }
  }
}

ion-card {
  position: relative;
  background: white;
  border-radius: 20px;
  height: 130px;
  margin: 10px;
  display: flex;
  border: 1px solid white;
  box-shadow: none;

  &.pointer {
    cursor: pointer;
  }

  &.auto {
    min-height: 130px;
    height: auto;
  }

  &.small {
    height: 90px;
    align-items: center;
  }

  .img-container {
    width: 70px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;

    img {
      height: 100%;
      width: auto;
      margin: 0;
      padding: 0;
      object-fit: cover;
    }

    .scrim {
      height: 100%;
      width: 100%;
      background: rgba(0, 0, 0, 0.42);
      position: absolute;
      top: 0;
      left: 0;
      display: flex;

      .icon {
        font-size: 30px;
        color: white;
        margin: auto;
      }
    }
  }

  .icon-container {
    width: 70px;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;

    .icon {
      font-size: 60px;
      color: white;

      &.small {
        font-size: 30px;
      }
    }
  }

  .values-container {
    width: 100%;
    padding: 5px;
    display: grid;

    .field {
      display: flex;
      margin: auto 10px;

      &.grid,
      .grid {
        display: grid;
        justify-items: start;
        align-items: center;
        align-content: center;
      }

      &.overflow,
      .overflow {
        overflow-y: auto;
        max-height: 80px;
        padding-top: 5px;
        margin-right: 0;
      }

      .icon {
        font-size: 18px;
        padding-right: 5px;
        color: black;
        margin: auto 0;
      }

      ion-card-title {
        @include boton1(black);
      }

      ion-title {
        padding: 0;
        position: relative;
        @include boton1(black);
        max-width: 80%;
      }

      ion-card-subtitle.value {
        @include cuerpoTexto1(black);
        // text-transform: capitalize;

        &.flex {
          display: flex;
          align-items: center;
        }

        &.bold {
          font-weight: bold;
        }

        &.italic {
          font-style: italic;
        }

        &.upper {
          text-transform: uppercase;
        }

        &.small {
          font-size: 12px;
        }
      }

      ion-card-subtitle.no-value {
        @include cuerpoTexto1(rgba(0, 0, 0, 0.42));
        font-style: italic;
      }
    }
  }

  .arrow-container {
    width: 50px;
    height: calc(100% - 20px);
    display: flex;
    justify-content: center;
    border-top-right-radius: 15px;
    border-bottom-right-radius: 15px;
    border-left: rgba(0, 0, 0, 0.05) solid 2px;
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    z-index: 0;

    i {
      color: $interactive;
      height: auto;
      margin: auto;
      font-size: 30px;
    }
  }

  ion-button {
    width: 50px;
    height: calc(100% - 20px);
    margin-top: 10px;
    margin-bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    --border-radius: 15px;
    --background: transparent;
    --box-shadow: none;
    --padding-start: 0;
    --padding-end: 0;

    i {
      height: auto;
      margin: auto;
      font-size: 30px;
      color: $interactive;
    }

    &.right {
      border-left: rgba(0, 0, 0, 0.05) solid 2px;
    }

    &.decrease {
      i {
        color: $cancel;
      }
    }
  }
}

ion-select,
ion-input,
ion-datetime {
  max-width: 100% !important;
  background: white;
  --padding-start: 8px;
  --padding-end: 8px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  border: 1px solid black;
  border-radius: 25px;
  --border-radius: 25px;
  font-size: 14px;
  --placeholder-opacity: 0.85;

  &.transparent {
    border: 1px solid white;
    background: transparent;
    color: white;
  }
}

ion-textarea {
  max-width: 100% !important;
  background: white;
  margin: 8px 0;
  --padding-start: 8px !important;
  --padding-end: 8px;
  --padding-top: 4px;
  --padding-bottom: 4px;
  border: 1px solid black;
  border-radius: 15px;
  font-size: 14px;
  --placeholder-opacity: 0.85;
}

span {
  &.italic {
    font-style: italic;
  }

  &.lighter {
    font-weight: 400;
  }
}

.item-container {
  width: 100%;
  padding: 10px 0;

  &.flex {
    display: flex;
    justify-content: space-between;

    .column {
      width: 48%;
    }
  }

  .input-btn-container {
    display: flex;
    align-items: center;

    ion-input {
      width: calc(100% - 34px - 16px);
    }
  }

  ion-label {
    padding: 5px 10px;
    font-size: 14px;
    font-weight: 900;

    &.lbl-input {
      font-size: 16px;
      color: white;
      text-transform: uppercase;
      font-weight: 400;

      &.center {
        text-align: center;
        padding: 0;
        text-transform: none;
      }
    }
  }
}

.img-toolbar-container {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  img {
    height: 30px;
  }
}

ion-list.list {
  width: 100%;
  background: transparent;
}

.list-segment {
  overflow: auto;
  height: calc(100% - 40px);
  z-index: -1;
}

ion-button.show-pass-button {
  margin: 0;
  margin-left: 16px;
  background: transparent;
  border: 1px solid white;
  border-radius: 20px;
  --border-radius: 20px;
  height: 34px;
  width: 34px;
  --padding-start: 0;
  --padding-end: 0;

  // &:active {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   border-color: rgba(0, 0, 0, 0.3);
  // }

  // &:hover {
  //   background-color: rgba(0, 0, 0, 0.3);
  //   border-color: rgba(0, 0, 0, 0.3);
  // }

  .icon {
    font-size: 30px;
    color: white;
  }
}

ion-item {
  --background: transparent;

  ion-text {
    font-size: 14px;
    text-align: center;
  }
}

@media screen and (max-width: 420px) {
  .popover-content {
    left: 0 !important;
    width: 100% !important;
  }
}

@media screen and (min-width: 600px) {
  .content {
    margin: 0 auto !important;
    width: 460px;
  }
}

@media screen and (min-width: 1028px) {
  ion-header.menu {
    ion-title {
      text-align: left !important;
      font-weight: 900 !important;
    }

    .search-button {
      display: flex !important;
    }
  }

  button.fab-button.search {
    display: none !important;
  }
}
